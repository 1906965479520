<template>
  <div>
    <label v-if="data.label" class="form__label">
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <span class="form__error">{{ data.error }}</span>
    <div class="hourForm">
      <form-generator :form="formHours" @formData="handleForm"></form-generator>
      <span class="form__item">:</span>
      <form-generator
        :form="formMinutes"
        @formData="handleForm"
      ></form-generator>
    </div>
  </div>
</template>
<script setup>
import FormGenerator from "@/components/form/FormGenerator.vue";
import HelperInput from "./HelperInput";
import { ref } from "vue";
const emit = defineEmits(["updateForm"]);
const props = defineProps(["data"]);
const formValue = ref({ hour: props.data.value ? props.data.value.split(':')[0] : '', minutes:  props.data.value ? props.data.value.split(':')[1] : '' });
console.log(props.data.value);

const formHours = [
  {
    type: "text",
    placeholder: "Hour",
    isNumber: true,
    maxLength: 2,
    name: "hour",
    value: formValue.value.hour

  },
];

const formMinutes = [
  {
    type: "text",
    placeholder: "Minutes",
    isNumber: true,
    maxLength: 2,
    name: "minutes",
    value: formValue.value.minutes

  },
];

const handleForm = (val) => {
  val.data.minutes ? (formValue.value.minutes = val.data.minutes) : false;
  val.data.hour ? (formValue.value.hour = val.data.hour) : false;

  if (formValue.value.minutes.length === 2 && formValue.value.hour.length >=1) {
    emit("updateForm", {
      field: `${props.data.name}`,
      value: `${formValue.value.hour}:${formValue.value.minutes}`,
    });
  }
};
</script>

<style lang="scss">
.hourForm {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  background-color: red($color: #000000);

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
  }
}
</style>
