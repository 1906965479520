<template>
    <div class="range">
        <div class="value">{{data.label}} <b>{{formatLargeNumber(rangeValue)}}</b></div>
        <input type="range" min="0" :max="data.maxInput" :step="data.maxInput > 1000 ? 10 : 1" v-model="rangeValue" @mouseup="emitData" @keyup="emitData">
    </div>  

</template>

<script>
import { ref } from '@vue/reactivity';
import {formatLargeNumber} from '@/composables/utils/formatNumber';
export default {
    props:['data'],
    setup(props, {emit}){
        const rangeValue = ref(props.data.presetValue);
        const emitData = () => {
            emit('updateForm', {field: `${props.data.name}`, value: rangeValue.value});
        }
        

        return {emitData, formatLargeNumber, rangeValue};
    },
    emits:['updateForm']
}
</script>

<style lang="scss">
@import "@/style/main";
.range{

}
.value {
  font-size:2rem;
  line-height: 60px;
}
input[type="range"] {
  display: block;
  -webkit-appearance: none;
  background-color: $color-primary;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: $color-primary;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: .3s ease-in-out;
}​
  input[type="range"]::-webkit-slider-thumb:hover {
    background-color: white;
    border: 2px solid $color-primary;
  }
  input[type="range"]::-webkit-slider-thumb:active {
    transform: scale(1.4);
  }
</style>