<template>
    <div>
        <label class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
        <span class="form__error">{{data.error}}</span>
        <div class="form__textarea--container">
            <div v-if="data.param && data.param.maxCharacters" class="form__textarea--counter">
                {{textareaLength}} / {{data.param.maxCharacters}}
            </div>
            <textarea 
                :id="textAreaId"
                :placeholder="data.placeholder" 
                class="form__textarea" :class="{'form__textarea--fixed': data.param && data.param.fixed ? data.param.fixed: false}" 
                v-model="inputs" 
                :disabled="data.disabled" 
            ></textarea>
        </div>
    </div>
</template>

<script>
import{ ref, computed, watch, onMounted} from 'vue';
import HelperInput from './HelperInput';

import {textareaAutogrow} from '@/composables/form/textarea';
export default {
    components: {HelperInput},
    props:['data', 'modelValue'],
    setup (props, { emit }) {
        const textareaLength = ref(1);
        const textAreaId = `textarea-${props.data.name}`;

          const inputs = computed({ 
            get: () => props.modelValue, 
            set: (value) => emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: value}) 
        }) 

        onMounted(() => {
            textareaAutogrow(textAreaId);
        })

        
        return {
            inputs,
            textareaLength,
            textAreaId
        }
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">

@import '@/style/main';
.form{
    &__textarea{
         box-shadow: $shadow-light;
         border: none;
         border-radius: 2px;
        width: 100%;
        height: 10rem;
        padding: 1.2rem 1.5rem;
        font-size: 1.5rem;
        resize: none;
        outline: none;
        &:focus {
            border-bottom: 3px solid $color-primary !important;
        }
        &--fixed{
            resize: none; 
        }

        &--container{
            margin: .7rem 0;
            position: relative;
        }

        &--counter{
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            text-align: right;
            font-size: 1.1rem;
        }
    }

}

</style>