<template>
  <label class="switch" :class="large ? 'switch__large' : ''">
        <input type="checkbox" class="switch__input" :class="large ? 'switch__input--large' : ''" v-model="bool">
        <span class="switch__slide switch__round" :class="large ? 'switch__slide--large' : ''"></span>
    </label>
</template>

<script>
import {computed} from 'vue';
export default {
    props: {
        val:{
            type:Boolean
        }, 
        large:{
            default: false
        }
    },
    setup(props, {emit}){
        const bool = computed({ 
            get: () => props.val, 
            set: (value) => emit('updateForm', {value: value}) 
        }) 
        
        return {bool}
    },
    emits:['updateForm']

}
</script>

<style lang="scss">
@import '@/style/main';

$size: 4rem;
$margin: 0.3rem;
$bullet:$size/2 - 2*$margin;

$size-large:6rem;
$bullet-large:$size-large/2 - 2*$margin;

.switch {
    position: relative;
    display: inline-block;
    width: $size;
    height: $size/2;

    &__large{
        width: $size-large;
        height: $size-large/2;
    }
    &__input {
        display: none;
    }
    &__slide {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        &::before {
            position: absolute;
            content: "";
            height: $bullet;
            width: $bullet;
            left:$margin;
            bottom:$margin;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        &--large{
            &::before{
                height: $bullet-large;
                width: $bullet-large;
            }
        }
    }
    &__round {
        border-radius: 3.4rem;
        &::before {
            border-radius: 50%;
        }
    }

    &__input:checked + &__slide::before {
        transform: translateX($size/2);
    }
    &__input--large:checked + &__slide::before {
        transform: translateX($size-large/2);
    }
    &__input:focus + &__slide {
        box-shadow: 0 0 1px $color-primary-dark;
    }
    &__input:checked + &__slide {
        background-color: $color-primary-dark;
    }
}

</style>