<template>

        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  
    
  </template>


<style lang="scss">
@use "sass:math";

@import '@/style/main';

.loader {
  border: .4rem solid #f3f3f3; /* Light grey */
  border-top: .4rem solid $color-primary; /* Blue */
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

$loading-width: 5rem;
$loading-height: 1.8rem;
$loading-bullet: 1rem;

.lds-ellipsis {
  display: flex;
  position: relative;
  width: $loading-width;
  height: $loading-height;
}
.lds-ellipsis div {
  position: absolute;
  top: math.div($loading-height - $loading-bullet, 2);
  width: $loading-bullet;
  height: $loading-bullet;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  transform: translateY(50%);
}
.lds-ellipsis div:nth-child(1) {
  left: math.div($loading-width, 10);
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: math.div($loading-width, 10);
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: math.div($loading-width, 5)*2;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: math.div($loading-width, 10)*7;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(math.div($loading-width, 10)*3, 0);
  }
}
</style>