<template>
    <template  v-for="item in form">
        <input-text  v-if="item.type=== 'text'" :data="item" :modelValue="item.value" class="form__item"  @updateForm="handleFormData"></input-text>
        <input-boolean v-else-if="item.type === 'boolean'" :data="item" :modelValue="item.value" class="form__item"  @updateForm="handleFormData"></input-boolean>
        <input-choice v-else-if="item.type==='choice'" :data="item"  class="form__item" @updateForm="handleFormData"></input-choice>
        <input-multiple-checkbox v-else-if="item.type==='multi-checkbox'" :data="item"  class="form__item" @updateForm="handleFormData" ></input-multiple-checkbox>
        <input-date-range v-else-if="item.type === 'date-range'" :data="item" class="form__item" @updateForm="handleFormData"></input-date-range>
        <input-textarea v-else-if="item.type==='textarea'" :data="item" :modelValue="item.value" class="form__item" @updateForm="handleFormData"></input-textarea>
        <input-date v-else-if="item.type === 'date'" :data="item" class="form__item" @updateForm="handleFormData"></input-date>
        <input-date-from-to v-else-if="item.type === 'date-from-to'" :data="item" class="form__item" @updateForm="handleFormData"></input-date-from-to>
        <input-checkbox v-else-if="item.type === 'checkbox'" :name="item.name" :modelValue="item.value" :label="item.label" class="form__item" @updateForm="handleFormData"></input-checkbox>
        <input-select v-else-if="item.type==='select'" :inputdata="item" @updateForm="handleFormData" class="form__item"></input-select>
        <input-range v-else-if="item.type==='range'" :data="item" @updateForm="handleFormData" class="form__item"></input-range>
        <input-csv v-else-if="item.type==='csv'" :data="item" @updateForm="handleFormData" class="form__item"></input-csv>
        <input-hour v-else-if="item.type==='hour'" :data="item" @updateForm="handleFormData" class="form__item"></input-hour>
        <input-file
      v-else-if="item.type === 'file'"
      :data="item"
      :modelValue="item.value"
      @updateForm="handleFormData"
      class="form__item"
    ></input-file>
</template>
</template>

<script setup>
import InputCheckbox from '@/components/form/InputCheckbox';
import InputDate from '@/components/form/InputDate';
import InputDateFromTo from '@/components/form/InputDateFromTo';
import InputDateRange from '@/components/form/InputDateRange';
import InputChoice from '@/components/form/InputChoice';
import InputCsv from '@/components/form/InputCsv';
import InputHour from '@/components/form/InputHour';
import InputFile from '@/components/form/InputFile';
import InputRange from '@/components/form/InputRange';
import InputSelect from '@/components/form/InputSelect';
import InputText from '@/components/form/InputText';
import InputTextarea from '@/components/form/InputTextarea';
import InputBoolean from '@/components/form/InputBoolean';
import InputMultipleCheckbox from '@/components/form/InputMultipleCheckbox';

import { ref } from '@vue/reactivity';

const props = defineProps(['form']);
const emit = defineEmits(['formData', 'search'])

const dataForm = ref({});
const handleFormData = (val) => {
    dataForm.value[val.field] = val.value;
    emit('formData', {data:dataForm.value})
}

</script>

<style lang='scss'>
@import '@/style/main';
@import '@/style/components/form';

</style>