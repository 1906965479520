<template>
  <div>
    <label class="form__label">
        {{data.label}}
        <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <span class="form__error">{{data.error}}</span>
    <input name="date" type="text" class="form__input" :placeholder="data.placeholder" :value="valDate" autocomplete="off">
  </div>
</template>

<script>
import moment from 'moment'
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import { computed, onMounted } from 'vue';

import HelperInput from './HelperInput';


export default {
    components:{HelperInput},
    props:['data', "modelValue"],
    setup(props, {emit}){
        const months = moment.months();

        const defaultStartDate = moment('0000-01-01');
        const defaultEndDate = moment(); 

        const valDate =`${defaultStartDate.format('LL')} - ${defaultEndDate.format('LL')}`;

        const listenerDate = () => {
             $('input[name="date"]').daterangepicker(
                {
                    startDate:defaultStartDate.format('LL'),
                    endDate: defaultEndDate.format('LL'),
                    showDropdowns: true,
                    autoUpdateInput: false,
                    "alwaysShowCalendars": true,
                    minYear: 0,
                    maxYear: parseInt(moment().format("YYYY"), 10),
                    ranges: {
                        // 'Today': [moment(), moment()],
                        // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        'Last Year': [moment().subtract(1, 'year').startOf('month'), moment()],
                        'All Time' : [defaultStartDate, moment()]
                        },
                    locale: {
                            format: "LL",
                        },
                    },
                function (start, end, label) {
                    $('input[name="date"]').val(`${start.format("LL")} - ${end.format("LL")}`);
                    emit('updateForm', {field: props.data.name, value: {start: start.format('YYYY-MM-DD'), end:end.format('YYYY-MM-DD')}})
                }
            );
        }

        onMounted(listenerDate);
        return{
            valDate
        }
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/daterangepicker';

</style>