<template>
  <div class="nav__container">
    <div class="nav">
      <div class="nav__logo nav__item">
        <img
          src="https://strapi.zappyschool.com/uploads/small_oe_logo_9069b73a88.png?updated_at=2023-03-15T09:58:24.975Z"
          alt=""
        />
      </div>

      <ul class="nav__content nav__item">
        <li v-for="n of navData">
          <router-link
            :to="{ name: n.name }"
            class="nav__link"
            :class="{ 'nav__link--selected': route.name === n.name }"
          >
            <Svg :icon="n.logo" size="2.4"></Svg>
          </router-link>
        </li>
      </ul>

      <ul class="nav__bottom nav__item">
        <li>
          <router-link :to="{ name: 'setting' }" class="nav__link">
            <Svg icon="setting" size="2.4"></Svg>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import navData from "@/composables/data/navigation.json";
import Svg from "@/components/utils/Svg";
import { useRoute } from "vue-router";
const route = useRoute();
</script>

<style lang="scss">
@import "@/style/main";

.nav {
  padding: 2rem $nav-padding-side;
  height: 100%;

  display: grid;
  grid-template-rows: 8rem 8fr 1fr 1fr;
  row-gap: 3rem;
  justify-items: center;
  align-items: center;

  // &__content{
  //   display: flex;
  //   align-items: center;
  //   justify-content: center

  // }

  &__link {
    @include buttonNoStyle;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 4px;

    & svg {
      fill: $color-white;
    }
    &:hover {
      background-color: lighten($color-text, 5);
    }

    &--selected {
      background-color: lighten($color-text, 8);
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & li {
      // width: 100%;
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-text;
    height: 100vh;
  }

  &__logo {
    width: $nav-image-width;

    & img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
