import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCookies from "vue-cookies";
import axios from "axios";



// AXIOS
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
// axios.defaults.withCredentials = true;
axios.defaults.headers["Accept-Language"] = "en";
axios.defaults.headers["Authorization"] = "Bearer " + $cookies.get("jwt");


createApp(App).use(store).use(router).use(VueCookies).mount('#app')
