<template>
<div>
    <label v-if="data.label" class="form__label">
        {{data.label}}
        <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <ul class="checkboxes">
      <li v-for="c of options">
        <input-checkbox  :name="c.name" :modelValue="c.value" :label="c.label" @updateForm="handleUpdate" ></input-checkbox>
      </li>
    </ul>
</div>
    
</template>

<script>
import InputCheckbox from "@/components/form/InputCheckbox";
import HelperInput from './HelperInput';
import { ref } from '@vue/reactivity';

export default {
    components:{
        InputCheckbox,
        HelperInput
    },
    props:['data'],
    setup(props, {emit}){
        const options = ref(props.data.options)
        
        const handleUpdate = (val) => {
            options.value.map(el => {
                if(el.name === val.field) el.value = val.value;
            })
            emit('updateForm', {field: props.data.name, value: options.value})
        }
        return{handleUpdate, options}
    },
    emits: ['updateForm']
}
</script>

<style lang="scss">
@import '@/style/main';

.checkboxes{
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    & li{
        display: flex;
        align-items: center;
        list-style: none;
        margin-bottom: 1rem;
        & h3{
            margin-left: 1rem;
        }
        &:not(:last-child){
            margin-right: 2rem;
        }
    }
}

</style>