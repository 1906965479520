<template>
  <div>
    <label v-if="data.label" class="form__label">
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <span class="form__error">{{ data.error }}</span>
    <input
      :type="data.input || 'text'"
      :placeholder="data.placeholder"
      class="form__input"
      :class="{ 'form__input--table': data.style === 'table' ? true : false }"
      v-model="inputs"
      :disabled="data.disabled"
      :style="`color: ${data.color ? data.color : 'inherit'}`"
      @keyup.enter="search"
      @keypress="
        [
          data.isNumber ? isNumber($event) : false,
          data.maxLength ? maxLength($event) : false,
        ]
      "
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import HelperInput from "./HelperInput";

const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["search", "updateForm"]);
const currentValue = ref(props.modelValue);
const inputs = computed({
  get: () => props.modelValue,
  set: (value) => {
    currentValue.value = value;
    emit("updateForm", {
      field: `${props.data.name}${
        props.data.field ? "." + props.data.field : ""
      }`,
      value: value,
    });
  },
});

/**
 * We check if the pressed key is a number of not.
 */
const isNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};

const maxLength = (evt) => {
  if (currentValue.value && currentValue.value.length >= props.data.maxLength) {
    evt.preventDefault();
  } else {
    return true;
  }
};
</script>

<style lang="scss">
@import "@/style/main";
@import "@/style/components/form";
</style>
