<template>
   <div>
    <label v-if="data.label" class="form__label">
        {{data.label}}
        <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <ul class="csv">
        <li v-for="d of dataUploaded" :key="d">
            {{d}}
        </li>
    </ul>

    <input type="file" @change="onFileChange">
    </div>
</template>

<script>
    import * as csv from "csvtojson";
    import HelperInput from './HelperInput';
import { ref } from '@vue/reactivity';


export default {
    components: {
        HelperInput
    },
    props:['data'],

setup(props, {emit}){
    const dataUploaded = ref([]);
    const onFileChange = (e) => {
            const files = e.target.files || e.dataTransfer.files;

            var reader = new FileReader();
            

            reader.onload = (i) => {
                const csvStr = reader.result;
                const result = generateJsonFromCsv(csvStr);
                csv({
                    noheader:false,
                    output: "json"
                })
                .fromString(csvStr)
                .then((csvRow)=>{ 
                    console.log(csvRow) // => [["1","2","3"], ["4","5","6"], ["7","8","9"]]
                    const data = getRightColumn(csvRow);
                    dataUploaded.value = data;
                    emit('updateForm', {field: props.data.name, value: data})
                })
            };

            reader.readAsBinaryString(files[0]);
            if (!files.length)
                return;
        }

        const getRightColumn = (data) => {
            //1) we determine the name of the right column;
            let res = [];
            let field = false;
            for( let o of Object.keys(data[0])){
                if(data[0][o].startsWith('BE:') && o.toLowerCase() != 'parent'){
                    field = o
                }
            }

            if(field){
                console.log(field);
                for(let k of data){
                    res.push(k[field]);
                }
            }
            return res;
        }

        const generateJsonFromCsv = (csv) => {
            const result = [];
            const lines = csv.split("\n");
                const header = lines[0].split(',');
                
                for(let i = 1; i < lines.length; i++){
                    let line = lines[i]
                    if(line){
                        let obj = {};
                        const re = /"/g;
                        line = re[Symbol.replace](line, '');
                        let lineArr = line.split(',');

                        for (let j = 0 ; j< lineArr.length; j++){
                            const head = header[j];
                            const val = lineArr[j];
                            if(head && val){
                                obj[head.trim()] = val.trim();
                            }
                        }
                        result.push(obj);
                    }
                    
                }
                return result
        }

        return {
            dataUploaded,
            onFileChange
        }
},
emits: ['updateForm'],
}
</script>

<style lang="scss">
@import "@/style/main";
.csv{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    & li{
        list-style: none;
        margin: 0 1rem 1rem 0;
        padding: 1rem;
        background-color: $color-background-light;
    }
}

</style>