import axios from "axios";
import store from "../../store";

export const getData = async (args) => {

  try {
    args.method = "GET";
    const res = await axios(args);
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
    return err.response;
  }
};

export const postData = async (args) => {

  args.method = "POST";
  try {
    const res = await axios(args);

    if (res.status === 200 || res.status === 201 || res.status === 206) {
      return res.data;
    }
  } catch (err) {
    store.dispatch('notification', {
      message:err.response.data ? err.response.data.message: err.response,
      color:'red'
    })
    console.log(err);
    console.log(err.response);
  }
};

export const patchData = async (args) => {
  try {
    args.method = "PATCH";

    const res = await axios(args);

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const deleteData = async (args) => {
  try {
    args.method = "DELETE";

    const res = await axios(args);

    if (res.status === 204) {
      return 'deleted';
    }
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
