<template>
  <div class="form__item">
    <label for="input-profile-gender" class="form__label">
      {{ inputdata.label }}
      <helper-input
        v-if="inputdata.helper"
        :message="inputdata.helper"
      ></helper-input>
      <button
        v-if="inputdata.action"
        class="form__label--btn"
        @click="handleAction"
      >
        {{ inputdata.action.label }}
      </button>
    </label>
    <select class="form__select" :name="inputdata.name" v-model="inputs">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
        :selected="option.selected ? true : false"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

import HelperInput from "./HelperInput";
const props = defineProps(["inputdata"]);
const emit = defineEmits(["updateForm", "action"]);

const options = ref(null);

onMounted(() => {
  options.value = generateOptions(props.inputdata.options);
});

const generateOptions = (options) => {
  let optionsArr = [];
  if (typeof options === "string" && options.startsWith("range")) {
    const ite = options.replace("range", "") * 1;

    for (let i = 1; i <= ite; i++) {
      // Runs 5 times, with values of step 0 through 4.
      const opt = {
        value: i,
        label: i,
      };
      optionsArr.push(opt);
    }
  } else {
    optionsArr = options;
  }
  return [optionsArr.find(el => el.disabled), ...optionsArr.filter(el => !el.disabled).sort((a, b) => a.label.localeCompare(b.label))];
};

const inputs = computed({
  get: () => props.inputdata.value,
  set: (value) =>
    emit("updateForm", {
      field: `${props.inputdata.name}${
        props.inputdata.field ? "." + props.inputdata.field : ""
      }`,
      value: value,
    }),
});

const handleAction = () => {
  emit("action", props.inputdata.action.value);
};
</script>

<style lang="scss" scoped>
@import "@/style/main";
</style>
