<template>
<div>
     <label for="" class="form__label">
        {{data.label}}
        <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <div class="icd">
        <div v-for="f of form" class="icd__item" :key="f.label">
            <button class="icd__selection" :class="{'icd__selection--selected': !f.selected}" @click="toggleSelection(f.name)">
                <div class="icd__selection--text">
                    {{f.label}}
                <span v-if="!f.selected" >
                    {{f.label_span}}
                </span>  
                    </div>
                <div v-if="!f.selected" class="icd__selection--edit">
                    <Svg icon="edit" size="2"></Svg>
                </div>
                <div v-else class="icd__selection--delete">
                    <Svg icon="delete" size="1.4"></Svg>
                </div>
            </button>

            <input-date v-if="f.selected" :data="f.data" @updateForm="handleDates"></input-date>
        </div>
    </div>
</div>
   

</template>
    
<script>
import { ref } from '@vue/reactivity';
import HelperInput from './HelperInput';
import InputDate from './InputDate.vue';
import Svg from '@/components/utils/Svg';

import moment from 'moment';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';

export default {
    components:{HelperInput, InputDate, Svg},
    props:['data', "modelValue"],
    setup(){
        const store = useStore();
        const form = ref([
            {
                name:"fromDate",
                selected: false,
                label:"From",
                label_span:"All time",
                data:{
                    value:moment().subtract(5, 'year').format('YYYY-MM-DD'), //
                    name:"creation-from",
                    field:"lower_date"
                }
            },
            {
                
                name:"untilDate",
                selected: false,
                label:"Until",
                label_span:"Today",
                data:{
                    value:"",
                    name:"creation-until",
                    field:"upper_date"
                }
            }
        ])

        onMounted(() => {
            // We set filter saved values
            updateForm();
        })

        const updateForm = () => {
            const dateCreationFilter = store.state.filters.start_date;
            if(dateCreationFilter){
                if( dateCreationFilter.lower_date ){
                    console.log('inin');
                    const indexFrom = form.value.findIndex(el => el.name === 'fromDate');
                    form.value[indexFrom].selected = true;
                    form.value[indexFrom].data.value = dateCreationFilter.lower_date;
                }  
                
                if(dateCreationFilter.upper_date){
                    console.log('in');
                    const indexTo = form.value.findIndex(el => el.name === 'untilDate');
                    form.value[indexTo].selected = true;
                    form.value[indexTo].data.value = dateCreationFilter.upper_date;
                }
            }
            console.log(dateCreationFilter, form.value);
            
        }

        const updateDateFilter = () => {
            //Reset creation filters
            store.state.filters.start_date = {} //{lower_date : Date, upper_date: Date}
            

            //We update filter when we click on from/until
            for(let i of form.value){
                if(i.selected){
                    store.state.filters.start_date[i.data.field] = i.data.value;
                }
            }
            console.log(store.state.filters);

        
        }
       const toggleSelection = (name) => {
           const index = form.value.findIndex(el => el.name === name);
           form.value[index].selected = !form.value[index].selected

           updateDateFilter();
       }

       const handleDates = (val) => {
        let field = null;
        if(val.field === 'creation-from') field = 'fromDate';
        else if(val.field === 'creation-until') field = 'untilDate';

        const index = form.value.findIndex(el => el.name=== field);
        form.value[index].data.value = val.value;

        updateDateFilter();
       }
        return {form, handleDates, toggleSelection}
    }
}
</script>

<style lang='scss'>
@import "@/style/main";

.icd{
    display: flex;
    justify-content: space-between;
    // width: 100%;
    // align-self: stretch;

    &__selection{
        @include buttonNoStyle;
        display: flex;
                    align-items: center;
            justify-content: space-between;
        font-weight: 700;
        padding: 1rem 2rem;
        font-size: 1.4rem;
        text-transform: uppercase;
        box-shadow: $shadow-light;
        width: 100%;

        background-color: $color-primary-light;

        & span{
            font-weight: 400;
            margin-left: .6rem;
        }

        &--selected{
            height: 100%;
            // justify-content: center;
            width: 100%;
            display: flex;
            flex-direction: row;

            // background-color: red;
            font-size: 1.8rem;
        }

        &--edit{
            transition: all .2s;
            &:hover{
                color:$color-primary-dark;
            }
        }
    }

    &__item{
        width: 100%;
        flex: 0 0 49%;
        
        margin-top: .5rem;
        

        & label{
            display: block;
            font-weight: 700;
            font-size: 1.4rem;
            border: 1px solid currentColor;
            padding: 1rem 2rem;
        }
    }
}

</style>