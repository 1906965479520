import axios from "axios";
import { createStore } from 'vuex'
import { getData } from "@/composables/api/request";


export default createStore({
  state: {
    auth:{
      isLogged: false
    },
    form: {
      display: false,
      instructions:{},
      refreshVersion: 0
    },
    notification: {
      color: "green",
      display: false,
      message: "",
    },
    tableUpdate: false,
    user:false
  },
  getters: {
  },
  mutations: {
    formCompleted(state){
      state.form.display = false;
      state.tableUpdate = true;
    },
    hideForm(state) {
      state.form.display = false;
    },
    hideNotification(state) {
      state.notification.display = false;
    },
    loggedIn(state, payload){
      state.auth.isLogged = payload;
    },
    setForm(state, data) {
      state.form.instructions = data;
      state.form.refreshVersion ++;
    },
    setNotificationMessage(state, { message, color }) {
      state.notification.message = message;
      state.notification.color = color;
    },
    setTableUpdate(state){
      state.tableUpdate = true;
    },
    setUser(state, payload){
      state.user = payload
    },
    showForm(state) {
      state.form.display = true;
    },
    showNotification(state) {
      state.notification.display = true;
    },
    unsetTableUpdate(state){
      state.tableUpdate = false;
    }
  },
  actions: {
    async autoLogin(state) {
      if (!state.state.user) {

        if ($cookies.get("jwt")) {
          //Set Bearer token for all further axios requests
          axios.defaults.headers["Authorization"] =
            "Bearer " + $cookies.get("jwt");
            console.log('in auto login')


          const user = await getData({url:"users/me"});

          if (user) {
            state.commit("setUser", user);
            state.commit("loggedIn", true);

          } else {
            $cookies.set("jwt", "", 0);
          }
        }
      }
    },
    form(state, data) {
      state.commit("setForm", data)
      state.commit("showForm");
    },
    notification(state, { message, color }) {
      state.commit("setNotificationMessage", { message, color });
      state.commit("showNotification");
      setTimeout(() => {
        state.commit("hideNotification");
      }, 7000);
    },
  },
  modules: {
  }
})
