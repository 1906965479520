import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store/index";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      protected: true
    }
    
  },
  {
    path: '/auth/:page',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/AuthView.vue'),
    meta:{
      protected: false
    }
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/ForgotPasswordView.vue'),
    meta:{
      protected: false
    }
  },
  {
    path: '/billing/:page?',
    name: 'billing',
    component: () => import(/* webpackChunkName: "billing" */ '../views/BillingView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/cron',
    name: 'cron',
    component: () => import(/* webpackChunkName: "cron" */ '../views/CronView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: "/recipes",
    name: "recipes",
    meta: {
      title: "Recettes",
    },
    component: () =>
      import(/* webpackChunkName: "recipes" */ "../views/RecipeView.vue"),
  },
  {
    path: "/recipe/:slug",
    name: "recipe",
    meta: {
      title: "Recette",
    },
    component: () =>
      import(/* webpackChunkName: "recipe" */ "../views/RecipeItemView.vue"),
  },
 
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '../views/CustomerView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/email',
    name: 'email',
    component: () => import(/* webpackChunkName: "email" */ '../views/EmailView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/log',
    name: 'log',
    component: () => import(/* webpackChunkName: "log" */ '../views/LogView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/file',
    name: 'file',
    component: () => import(/* webpackChunkName: "files" */ '../views/FileView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "setting" */ '../views/SettingView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import(/* webpackChunkName: "stock" */ '../views/StockView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/WalletView.vue'),
    meta:{
      protected: true
    }
  },
  {
    path: '/website',
    name: 'website',
    component: () => import(/* webpackChunkName: "website" */ '../views/WebsiteView.vue'),
    meta:{
      protected: true
    }
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch("autoLogin");
  console.log('autologin');

  //Any page needs an authentication

  if(!store.state.auth.isLogged && to.meta.protected){
    next({ name: "auth", params: { page: "login" } });
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    // const lang = to.query.locale ?
    // console.log(to.query.locale);
    document.title = `${process.env.VUE_APP_TITLE} | ${nearestWithTitle.meta.title}`;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(
          key,
          key === "content" ? tagDef[key][store.state.language] : tagDef[key]
        );
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
})

export default router
