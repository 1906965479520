<template>
  <div>
    <label v-if="data.label" class="form__label">
        {{data.label}}
        <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <span class="form__error">{{data.error}}</span>
    <input :name="data.name" type="text" class="form__input" :placeholder="data.placeholder" :value="valDate" autocomplete="off">
  </div>
</template>

<script>
import moment from 'moment'
import daterangepicker from 'daterangepicker';
import $ from 'jquery';
import { computed, onMounted } from 'vue';

import HelperInput from './HelperInput';


export default {
    components:{HelperInput},
    props:['data', "modelValue"],
    setup(props, {emit}){
        const months = moment.months();

        const defaultDate = props.data.value ? moment(props.data.value) : moment();

        const valDate =`${defaultDate.format('LL')}`;

        const listenerDate = () => {
             $('input[name="'+props.data.name+'"]').daterangepicker(
                {
                    singleDatePicker: true,
                    showDropdowns: true,
                    autoUpdateInput: false,
                    "alwaysShowCalendars": true,
                    maxYear: parseInt(moment().format("YYYY"), 10),
                    locale: {
                            format: "LL",
                        },
                    },
                function (start, end, label) {
                    $('input[name="'+props.data.name+'"]').val(`${start.format("LL")}`);
                    emit('updateForm', {field: props.data.name, value: start.format('YYYY-MM-DD')})
                    console.log('in here');
                }
            );
            // We emit at creation to save the default date
            emit('updateForm', {field: props.data.name, value: moment(valDate, 'LL').format('YYYY-MM-DD')})
        }

        onMounted(listenerDate);
        return{
            valDate
        }
    },
    emits: ['updateForm'],
}
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/daterangepicker';

</style>