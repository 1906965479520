<template>
  <div>
    <label v-if="data.label" class="form__label">
      {{ data.label }}
      <helper-input v-if="data.helper" :message="data.helper"></helper-input>
    </label>
    <span class="form__error">{{ data.error }}</span>
    <div class="bool">
      <div class="bool__switch">
        <Switch :val="modelValue" @updateForm="handleSwitch" />
      </div>

      <div class="bool__content">
        <h3>
          {{ data.title }}
        </h3>
        <h4 v-if="data.description">
          {{ data.description }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script setup>
import Switch from "@/components/form/Switch";
import HelperInput from "./HelperInput";

const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const handleSwitch = (val) => {
  emit("updateForm", { field: props.data.name, value: val.value });
};
</script>

<style lang="scss" scoped>
.bool {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__switch {
    margin-right: 2rem;
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h3 {
      font-weight: 400;
    }

    & h4 {
      font-weight: 300;
    }
  }
}
</style>
