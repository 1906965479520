<template>
  <Popup />
  <div v-if="route.meta && route.meta.protected ? false : true">
    <router-view />
  </div>
  <div class="app" v-else>
    <div class="app__nav">
      <Navigation></Navigation>
    </div>
    <div class="app__content">
      <router-view />
    </div>
  </div>
</template>

<script setup>
import Navigation from "@/components/Navigation";
import Popup from "@/components/popup/Popup";
import { useRoute } from "vue-router";
const route = useRoute();
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins:100,300,400,700,900&display=swap");
@import "@/style/main.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
}

#app {
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;

  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
  padding: 0rem; /*Mets une bordure au site*/

  & li {
    // list-style: none;
  }
}

.app {
  display: grid;
  grid-template-columns: $nav-width 1fr;
  height: 100vh;

  &__content {
    background-color: rgb(246, 246, 246);
    padding: 2rem;
  }
}
</style>
